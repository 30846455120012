.align-right{
  text-align: right;
}

.form-left-label .ant-form-item-label {
  text-align: left;
}
.ant-table-row {
  vertical-align: top;
}
